import qs from 'qs';

import { fetcher } from '@/utils/http';
import { HttpMethod } from '@proprioo/hokkaido';

import { GeoResponseAPI, LocationSearchType } from './interfaces';

export const getLocationByLatLng = async (
  lat: number,
  lng: number,
  type: LocationSearchType
): Promise<GeoResponseAPI[]> => {
  const params = qs.stringify({ lat, lng, type }, { addQueryPrefix: true });

  return await fetcher<GeoResponseAPI[]>(`/api/location/search${params}`);
};

export const getLocationsByIds = async (
  ids: string[] = [],
  url: string
): Promise<GeoResponseAPI[]> => {
  if (!ids.length) {
    return Promise.resolve([]);
  }

  return await fetcher<GeoResponseAPI[]>(`${url}/api/location/searchByIds`, {
    body: JSON.stringify({ ids: [...new Set(ids)] }),
    method: HttpMethod.POST
  });
};
