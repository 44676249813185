import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { typedKeys } from '@proprioo/hokkaido';
import { BlockCheckIcon } from '@proprioo/salatim';

import { DEFAULT_OTHER } from '../filtersProvider/FiltersProvider';
import { OtherCriteriasProps } from '../search/interfaces';
import { CRITERIAS } from '../search/utils';
import { Layout } from './OtherCriterias.styles';

export type OtherCriteriasFCProps = {
  other?: OtherCriteriasProps;
  updateValue(dic: OtherCriteriasProps): void;
};

const OtherCriterias: FC<OtherCriteriasFCProps> = ({ other, updateValue }) => {
  const { t } = useTranslation();

  const [internalOther, updateInternalOther] = useState<OtherCriteriasProps>({
    ...DEFAULT_OTHER,
    ...other
  });

  const updateValues = (checked: boolean, name: string) => {
    const otherFilter = { ...internalOther, [name]: Boolean(checked) };
    const otherInternal = { ...internalOther, [name]: checked };
    updateValue(otherFilter);
    updateInternalOther(otherInternal);
  };

  const internalTyped = typedKeys<OtherCriteriasProps>(internalOther);

  useEffect(() => {
    if (other) {
      updateInternalOther({ ...internalOther, ...other });
    }
  }, [other]);

  return (
    <Layout>
      {internalTyped.map((internal, key) => {
        const { name, text, dataTest, image } = CRITERIAS[internal];
        const checked = internalOther[internal];

        return (
          <div key={key}>
            <BlockCheckIcon
              name={t(name)}
              text={t(text)}
              dataTest={dataTest}
              image={image}
              checked={Boolean(JSON.parse(`${checked}`))}
              onClick={checked => updateValues(checked, internal)}
            />
          </div>
        );
      })}
    </Layout>
  );
};

export default OtherCriterias;
