import styled from 'styled-components';

import {
  breakpoints,
  colors,
  convertPxToRem,
  fonts,
  paragraphs
} from '@proprioo/salatim';

export const InputLayout = styled.input`
  ${paragraphs.body1};
  min-height: ${convertPxToRem(16)};
  width: 100%;
  display: block;
  border: 0;
  background: none;
  font-size: ${fonts.base};
  caret-color: ${colors.dark.base};
  line-height: ${convertPxToRem(12)};
  position: relative;
  z-index: 1;
  margin-left: ${convertPxToRem(4)};
  color: ${colors.dark.base};

  ::placeholder {
    color: ${colors.dark.base};
    padding-top: ${convertPxToRem(3)};
  }

  :not(:disabled) {
    &:focus,
    &:active {
      outline: none;
    }
  }

  @media (min-width: ${breakpoints.base}px) {
    min-width: ${convertPxToRem(240)};
  }
`;

export const FocusBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
