import styled from 'styled-components';

import { breakpoints, convertPxToRem } from '@proprioo/salatim';

export const Layout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${convertPxToRem(16)};
  grid-row-gap: ${convertPxToRem(16)};

  label {
    width: 100%;
    text-align: center;
  }

  @media (min-width: ${breakpoints.base}px) {
    grid-template-columns: repeat(7, 1fr);
  }
`;
