import { ReactElement } from 'react';

import Success from '@/assets//icons/toast-success.svg';
import Error from '@/assets/icons/toast-error.svg';
import Warning from '@/assets/icons/toast-warning.svg';

import { ToastStatus } from './interfaces';

export const getToastIcon = (status: ToastStatus): ReactElement => {
  switch (status) {
    case ToastStatus.ERROR:
      return <Error data-test="error-icon" />;
    case ToastStatus.WARNING:
      return <Warning data-test="warning-icon" />;
    default:
      return <Success data-test="success-icon" />;
  }
};
