import React, { ChangeEvent, Component, Fragment, ReactElement } from 'react';

import { compose, nullOrString } from '@proprioo/hokkaido';
import { GMapProps, InputType } from '@proprioo/salatim';

import { FocusBorder, InputLayout } from './GmapInput.styles';

export type GmapInputProps = {
  label: string;
  value: nullOrString;
  field: ReactElement;
  gmap: GMapProps;
};

export type StateProps = {
  value: nullOrString;
  focused: boolean;
};

class GmapInput extends Component<GmapInputProps, StateProps> {
  constructor(props: GmapInputProps) {
    super(props);

    this.state = {
      value: props.value,
      focused: false
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
  }

  componentDidUpdate(prevProps: GmapInputProps) {
    const { value } = this.props;
    if (prevProps.value !== value) {
      this.setState(prevState => ({
        ...prevState,
        value
      }));
    }
  }

  handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = this.props;
    this.setState({
      value: value ? event.target.value : value
    });
  }

  handleFocus() {
    const { value } = this.state;
    this.setState(prevState => ({
      ...prevState,
      focused: true
    }));

    if (value) {
      this.props.gmap.onChange({ target: { value } });
    }
  }

  handleBlur() {
    this.setState(prevState => ({
      ...prevState,
      focused: false
    }));
  }

  render() {
    const { value, focused } = this.state;
    const { field, gmap, label } = this.props;

    const gmapProps: GMapProps = {
      ...gmap,
      onChange: compose(gmap && gmap.onChange, this.handleOnChange),
      onBlur: compose(gmap && gmap.onBlur, this.handleBlur)
    };

    return (
      <Fragment>
        <InputLayout
          {...gmapProps}
          id="searchInput"
          name="searchInput"
          data-test="search-input"
          type={InputType.TEXT}
          value={value || ''}
          onFocus={this.handleFocus}
          autoComplete="off"
          placeholder={label}
        />
        {field && <Fragment>{field}</Fragment>}
        {focused && <FocusBorder />}
      </Fragment>
    );
  }
}

export default GmapInput;
