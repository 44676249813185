import { useEffect } from 'react';

import { LIBRARIES } from '@/constants/constants';
import { logError } from '@/utils/log';
import { useJsApiLoader } from '@react-google-maps/api';

const SETUP_GOOGLE_MAPS = {
  googleMapsApiKey: `${process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY}`,
  libraries: LIBRARIES
};

const useGoogleMaps = () => {
  const { isLoaded, loadError } = useJsApiLoader(SETUP_GOOGLE_MAPS);

  useEffect(() => {
    if (loadError) {
      logError('Failed to load Google Maps', { error: loadError });
    }
  }, [loadError]);

  return { isLoaded };
};

export default useGoogleMaps;
