import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { logError } from '@/utils/log';
import {
  geocodeByAddress,
  getGeoSearchSuggestions,
  PlacesAutocomplete,
  Suggestion
} from '@proprioo/salatim';

import GmapInput from './input/GmapInput';
import Suggestions from './suggestions/Suggestions';

export enum PLACE_TYPES {
  REGION = '(regions)',
  ADRESS = 'address',
  GEOCODE = 'geocode'
}

export type GmapsAutocompleteProps = {
  isLoaded: boolean;
  value: string;
  country?: [];
  label?: string;
  types?: PLACE_TYPES[];
  withGeocodeType?: boolean;
  onChange(value: string): void;
  onSelect(value: google.maps.GeocoderResult): void;
};

export const COUNTRY = 'FR';

const GmapSearch: FC<GmapsAutocompleteProps> = ({
  isLoaded,
  value,
  country = [COUNTRY],
  label = 'searchInputPlaceholder',
  types = [PLACE_TYPES.REGION],
  withGeocodeType = false,
  onChange,
  onSelect
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleChange = (address: string) => {
    setIsOpen(true);
    onChange(address);
  };

  const handleSelect = async (address: string) => {
    if (!address) {
      return;
    }

    setIsOpen(false);

    try {
      const [result] = await geocodeByAddress(address);
      onSelect(result);
    } catch (error) {
      logError(`Failed to geocode by address: ${address}`, { address, error });
    }
  };

  return (
    <Fragment>
      {isLoaded && (
        <PlacesAutocomplete
          value={value}
          onChange={handleChange}
          onSelect={handleSelect}
          highlightFirstSuggestion={true}
          searchOptions={{
            types,
            componentRestrictions: { country }
          }}
        >
          {({
            suggestions,
            getInputProps,
            getSuggestionItemProps,
            loading
          }) => {
            const filteredSuggestions = withGeocodeType
              ? getGeoSearchSuggestions(suggestions as Suggestion[])
              : suggestions;

            return (
              <GmapInput
                data-test="select-wrapper-input-base"
                label={t(label)}
                value={value}
                gmap={{
                  ...getInputProps({
                    autoFocus: false
                  })
                }}
                field={
                  !loading && Boolean(filteredSuggestions.length) && isOpen ? (
                    <Suggestions
                      suggestions={filteredSuggestions as Suggestion[]}
                      getSuggestionItemProps={getSuggestionItemProps}
                    />
                  ) : (
                    <Fragment />
                  )
                }
              />
            );
          }}
        </PlacesAutocomplete>
      )}
    </Fragment>
  );
};

export default GmapSearch;
