import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlgoliaSearchState,
  LocationState
} from '@/components/search/search/interfaces';
import {
  getDecodedGeom,
  getLocationIds,
  getLocationNameWithoutDistrict
} from '@/components/search/search/utils';
import { LOCATION_STATE_FROM_PLACE_ID } from '@/constants/constants';
import useGoogleMaps from '@/hooks/useGoogleMaps/useGoogleMaps';
import { logError } from '@/utils/log';
import { toastError } from '@/utils/notification';
import {
  GeocoderResult,
  getFormattedAddressComponent
} from '@proprioo/hokkaido';
import { GmapsGeocode } from '@proprioo/salatim';

import GmapSearch, { COUNTRY, PLACE_TYPES } from '../gmapSearch/GmapSearch';
import Tags from '../tags/Tags';
import { getLocationByLatLng } from './fetch';
import {
  GeocodeWrapper,
  GmapWrapper,
  Layout,
  TagsWrapper
} from './LocationRefinement.styles';
import { checkLatLngBounds, getLocationType } from './utils';

export type LocationRefinementProps = {
  locationState?: LocationState[];
  withInputBase?: boolean;
  applyFilters?(): void;
  updateFilters?(state: AlgoliaSearchState): void;
  updateLocationState?(locationState: LocationState[]): void;
};

const LocationRefinement: FC<LocationRefinementProps> = ({
  withInputBase = false,
  locationState = [],
  applyFilters,
  updateFilters,
  updateLocationState
}) => {
  const { t } = useTranslation();
  const { isLoaded } = useGoogleMaps();

  const locationIds = useMemo(
    () => getLocationIds(locationState),
    [locationState]
  );

  const [temporaryLocations, updateTemporaryLocations] =
    useState<string[]>(locationIds);
  const [currentLocation, updateCurrentLocation] = useState<string>('');

  useEffect(() => {
    if (applyFilters) {
      applyFilters();
    }
  }, [temporaryLocations]);

  const updateLocations = (locationState: LocationState[]) => {
    const locationIds = getLocationIds(locationState);
    const uniqueIds = [...new Set(locationIds)];

    if (updateLocationState) {
      const locationStateWithoutDuplicate = locationState.reduce(
        (acc: LocationState[], location) => {
          const { id: currentId } = location;

          const foundLocation = acc.find(({ id }) => id === currentId);

          if (!foundLocation) {
            acc.push(location);
          }

          return acc;
        },
        []
      );

      updateLocationState(locationStateWithoutDuplicate);
    } else if (updateFilters) {
      updateFilters({ localisation: uniqueIds });
    }

    updateTemporaryLocations(uniqueIds);
  };

  const onSelect = async (addressComponents: GeocoderResult) => {
    const { types, place_id } = addressComponents;
    const {
      postalCode,
      location: { lat, lng }
    } = getFormattedAddressComponent(addressComponents);

    const locationType = getLocationType(types, postalCode?.long_name);
    const isValidLatLng = checkLatLngBounds({ lat, lng });

    if (isValidLatLng) {
      // This condition is handling place_id causing trouble
      if (LOCATION_STATE_FROM_PLACE_ID[place_id]) {
        const newLocationState: LocationState[] = [
          ...locationState,
          LOCATION_STATE_FROM_PLACE_ID[place_id]
        ];

        updateLocations(newLocationState);
      } else if (locationType) {
        try {
          const [currentLocation] = await getLocationByLatLng(
            lat,
            lng,
            locationType
          );

          if (currentLocation) {
            const { id, name, geom, type } = currentLocation;

            const decodedGeom = getDecodedGeom(geom);
            const nameWithoutDistrict = getLocationNameWithoutDistrict(name);

            const newLocationState: LocationState[] = [
              ...locationState,
              {
                id,
                name: nameWithoutDistrict,
                geom: decodedGeom,
                type,
                coordinates: []
              }
            ];

            updateLocations(newLocationState);
          } else {
            logError(
              `No location found by Geo: ${addressComponents.formatted_address}`,
              {
                addressComponents,
                lat,
                lng,
                locationType
              }
            );
            toastError('geoSearchError');
          }
        } catch (error) {
          toastError('geoSearchError');
        }
      } else {
        logError(`No location type found for ${postalCode?.long_name}`, {
          addressComponents,
          lat,
          lng,
          postalCode
        });
        toastError('geoSearchError');
      }
    } else {
      toastError('outOfBoundarySearch');
    }

    updateCurrentLocation('');
  };

  return (
    <Layout>
      <TagsWrapper>
        <Tags locationState={locationState} updateLocations={updateLocations} />
        {withInputBase ? (
          <GeocodeWrapper
            data-test="gmap-geocode"
            haveValue={Boolean(locationIds.length) || false}
          >
            <GmapsGeocode
              country={COUNTRY}
              disabled={false}
              error={false}
              id="search-geocode"
              label={t('fixedSearchInputPlaceholder')}
              onChange={updateCurrentLocation}
              onSelect={onSelect}
              types={[PLACE_TYPES.GEOCODE]}
              value={currentLocation}
              withGeocodeType={true}
            />
          </GeocodeWrapper>
        ) : (
          <GmapWrapper data-test="gmap-search">
            <GmapSearch
              isLoaded={isLoaded}
              label="searchInputPlaceholderWithGeo"
              onChange={updateCurrentLocation}
              onSelect={onSelect}
              types={[PLACE_TYPES.GEOCODE]}
              value={currentLocation}
              withGeocodeType={true}
            />
          </GmapWrapper>
        )}
      </TagsWrapper>
    </Layout>
  );
};

export default LocationRefinement;
