import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMobileDevice } from '@proprioo/salatim';

import { ToastStatus } from './interfaces';
import { IconWrapper, TextWrapper, ToastWrapper } from './Toast.styles';
import { getToastIcon } from './utils';

type ToastProps = {
  status: ToastStatus;
  text: string;
};

const Toast: FC<ToastProps> = ({ status, text }) => {
  const { t } = useTranslation();

  const isMobile = useMobileDevice();

  const toastIcon = getToastIcon(status);

  return (
    <ToastWrapper data-test="toast-wrapper" isMobile={isMobile} status={status}>
      <IconWrapper data-test="icon-wrapper">{toastIcon}</IconWrapper>
      <TextWrapper data-test="text-wrapper">{t(text)}</TextWrapper>
    </ToastWrapper>
  );
};

export default Toast;
