import React, { FC } from 'react';

import { Option, Suggestion, SuggestionItem } from '@proprioo/salatim';

import { ItemContainer, ItemLayout, Layout } from './Suggestions.styles';

type SuggestionsProps = {
  suggestions: Suggestion[];
  getSuggestionItemProps<SuggestionProps extends Record<string, unknown>>(
    suggestion: Suggestion,
    options?: SuggestionProps
  ): SuggestionItem & SuggestionProps;
};

const Suggestions: FC<SuggestionsProps> = ({
  suggestions,
  getSuggestionItemProps
}) => (
  <Layout>
    <ItemContainer>
      {suggestions.map((suggestion, index) => (
        <ItemLayout
          data-test="item-layout"
          {...getSuggestionItemProps(suggestion, {
            key: `suggestion-gmap-${index}`
          })}
          key={index}
        >
          <Option
            value=""
            option={{ label: suggestion.description, value: suggestion.id }}
          />
        </ItemLayout>
      ))}
    </ItemContainer>
  </Layout>
);

export default Suggestions;
