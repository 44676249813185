import { Location, POSTAL_CODES } from '@proprioo/hokkaido';
import { GoogleSearchType } from '@proprioo/salatim';

import { LocationSearchType } from './interfaces';

export const getLocationType = (
  types: string[],
  postalCode?: string
): LocationSearchType | undefined => {
  if (types.includes(GoogleSearchType.ADMINISTRATIVE_AREA_LEVEL_1)) {
    return LocationSearchType.REGION;
  }

  if (types.includes(GoogleSearchType.ADMINISTRATIVE_AREA_LEVEL_2)) {
    return LocationSearchType.COUNTY;
  }

  if (types.includes(GoogleSearchType.NEIGHBORHOOD)) {
    return LocationSearchType.NEIGHBORHOOD;
  }

  if (types.includes(GoogleSearchType.POSTAL_CODE) && postalCode) {
    if (POSTAL_CODES[postalCode]) {
      return LocationSearchType.SUBMUNICIPALITY;
    } else {
      return LocationSearchType.MUNICIPALITY;
    }
  }

  if (types.includes(GoogleSearchType.SUBLOCALITY)) {
    return LocationSearchType.SUBMUNICIPALITY;
  }

  if (types.includes(GoogleSearchType.LOCALITY)) {
    return LocationSearchType.MUNICIPALITY;
  }

  return;
};

export const checkLatLngBounds = ({ lat, lng }: Location): boolean => {
  // France polygon (Corse not included)
  const polygon = new google.maps.Polygon({
    paths: [
      { lat: 51.3576328, lng: 2.3144245 },
      { lat: 48.5517282, lng: -5.8395767 },
      { lat: 43.2872652, lng: -2.2024155 },
      { lat: 41.2518709, lng: 3.5803413 },
      { lat: 43.5844944, lng: 8.2448101 },
      { lat: 46.720917, lng: 7.4511337 },
      { lat: 48.9868074, lng: 8.5756016 },
      { lat: 51.4181045, lng: 2.3071289 }
    ]
  });

  return google.maps.geometry.poly.containsLocation({ lat, lng }, polygon);
};
