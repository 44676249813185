import styled from 'styled-components';

import { convertPxToRem } from '@proprioo/salatim';

export const TagsWrapper = styled.div`
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  gap: ${convertPxToRem(4)} ${convertPxToRem(2)};
`;
