import styled, { css } from 'styled-components';

import {
  colors,
  convertPxToRem,
  fonts,
  InterFont,
  radius,
  scrollbar,
  shadows
} from '@proprioo/salatim';

type SelectionOptionProps = {
  isSelected: boolean;
};

export const Layout = styled.div`
  position: absolute;
  width: 100%;
  top: ${convertPxToRem(56)};
  left: 0;
  background: white;
  border-radius: 0 0 ${radius.big} ${radius.big};
  padding: ${convertPxToRem(16)};
  border: ${convertPxToRem(1)} solid ${colors.terracota.base};
  box-shadow: ${shadows.level2};
  border-radius: ${radius.biggest};
  overflow: auto;
  z-index: 99;
`;

export const ItemContainer = styled.div`
  ${scrollbar};
  overflow: auto;
  height: 100%;
  max-height: ${convertPxToRem(100)};
`;

export const ItemLayout = styled.div`
  ${InterFont};
  font-size: ${fonts.small};

  & + & {
    margin-top: ${convertPxToRem(8)};
  }
`;

export const Label = styled.div<SelectionOptionProps>`
  ${InterFont};
  font-weight: lighter;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: ${convertPxToRem(32)};

  ${props =>
    !props.isSelected &&
    css`
      padding-right: 0;
    `}
`;
