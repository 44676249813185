import styled, { css } from 'styled-components';

import { convertPxToRem } from '@proprioo/salatim';

export const Layout = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const GmapWrapper = styled.div`
  display: inline-block;
  flex: 1;
  min-height: ${convertPxToRem(16)};

  &:not(:first-child) {
    margin-left: ${convertPxToRem(12)};
  }

  input {
    margin-left: 0;
    padding: 0;
  }
`;

export const GeocodeWrapper = styled.div<{ haveValue: boolean }>`
  width: 100%;
  margin-left: ${convertPxToRem(4)};
  margin-bottom: ${convertPxToRem(4)};

  ${({ haveValue }) =>
    haveValue &&
    css`
      margin-top: ${convertPxToRem(24)};
    `}
`;

export const TagsWrapper = styled.div`
  width: 100%;
  margin: 0 0 -${convertPxToRem(4)} 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
